<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="5">
                    <h4>Account Legs Entries</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>
              <CCol md="2">                 
                    <!-- <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"
                        :filename="'Account-Legs-Entries'"
                        :sheetname="'Account-Legs-Entries'"
                        >
                        Download
                    </vue-excel-xlsx>                  -->
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>
              </CCol>
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

          <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
          </CRow> 

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

                    <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>


              <CRow>
                <CCol col="12" class="text-left"> 
                <CInput
                  label="Transaction ID"
                  v-model = "trxID">
                  <template #append>
                    <CButton color="primary" v-on:click="actionfind()" >Find!</CButton>
                    <CButton color="warning" v-on:click="actionclear()">Clear</CButton>
                  </template>
                </CInput>
                </CCol> 
              </CRow>

        <div class="table-responsive scroll-y">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" sort-by="createDate" sort-desc="true" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate }}
              </template>

              <template v-slot:cell(amount)="{ item }"> 
                    {{ item.amount | formatNum }}
              </template>               

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ReportService from '@/api/ReportService.js';
import moment from 'moment';

 export default {
    name: "acclegsentries",
    components: {},     
    data() {
      return {        
        spinner:false,
        trxID : "",
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {
            key: 'accountRef',
            label : 'Account Ref',
            sortable: true
          },           
          {
            key: 'amount',
            label : 'Amount',
            sortable: true
          }          
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        formPasswd:false,
        filename:"acc-legs-entries.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
      },

    created () {       
       this.setColumns();  
       this.setDefaultPasswd(); 
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};   var colkey =[];      

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          } 
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3]; 
                  // if(key3==="transactionDate"){
                  //   var val = new Date(val).toISOString().substr(0, 19).replace("T"," ");
                  // }                
                  var ckey = colkey[key4]; 
                  if(key3===ckey){  init[key4] = val; }              
                }
              }
              data.push(init); 
          } 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      formatNum(value){
         return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
      }, 

      dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
      },  

      setDataRows(resp,keydate,keyamount){

          var tzoffset = (new Date()).getTimezoneOffset() * 60000;

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){                        
                       var strdate = resp2[key2]; 
                       //var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                       var ts = new Date(strdate).getTime();
                       var dt = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
                       resp[key][keydate] = dt;
                    }
                    if(key2 === keyamount){ 
                       resp[key][keyamount] = resp2[key2];
                    }
                 }
            }

          this.data  = resp;
      }, 

      actionclear(){
        this.trxID = '';
        this.items = []; this.data = [];
      },

      actionfind(){ 
          var trxID = this.trxID;
          const digits_only = string => [...string].every(c => '0123456789'.includes(c));
          var isNumber = digits_only(trxID); 

          if(trxID && isNumber){
             this.spinner = true;  
             this.getAccLegsEntries(this.trxID);
          } else {
            this.msg   = 'Invalid Transaction ID!';  this.items = [];
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
          }
          
      },

      getFilterData(){          
         this.data  = this.list;
      },

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },

      getAccLegsEntries: function(trxID) {
        ReportService.getAccLegsEntries(trxID).then(resp => {  //console.log(resp);

            for (var key in resp) {                
              var resp2 = resp[key];
                for (var key2 in resp2) { 
                    if(key2=="amount"){  
                        resp[key].amount = resp2[key2].amount;
                    }
                 }
            }

            this.spinner = false;         
            this.items = resp; 

            this.setDataRows(resp,'createDate','amount');
                    

        }, error => {
           this.loading = false;
        });
      },

    }
 }; 

</script>

<style>
    .scroll-y{ overflow-y:scroll;}    
</style>